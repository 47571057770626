<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 充值协议
 * @Date: 2020-12-24 21:44:40
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-24 22:12:13
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/Reag.vue
-->
<template>
  <div class="user_content">
    <div id="reag_test" class="reag_test" v-html="str" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import {
  getBalanceRule
} from '@/services/userApi'

export default {
  data() {
    return {
      str: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getBalanceRule().then(res => {
        if (Number(res.code) === 200) {
          const str = res.data.str
          this.str = str.replace(/\s+/g, '<br/>')
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .reag_test {
    line-height: 25px;
    padding: 20px 13px;
    text-align: left;
  }
}
</style>
